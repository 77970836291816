.wrapper {
  width: 100vw;
  min-height: 100vh;
  padding: 80px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  background: url(./assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.nav img {
  width: 117px;
}

.languages {
  display: flex;
  align-items: center;
}

.languages img {
  width: 36px;
  margin-left: 30px;
  cursor: pointer;
}

.main {
  width: 100%;
  max-width: 400px;
  margin-bottom: 100px;
  min-height: calc(100vh - 181px - 100px - 50px - 160px);
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer div {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding: 30px;
  }
  .main {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 350px) {
  .wrapper {
    padding: 20px;
  }
}
